<template>
  <div id="mentions_legales_periodiques">
    <MentionsLegalesPeriodiquesDe v-if="country === 'de'" />
    <MentionsLegalesPeriodiquesFr v-else />
  </div>
</template>

<script>
import MentionsLegalesPeriodiquesFr from "@/components/legal/periodiques/MentionsLegalesPeriodiquesFr.vue";
import MentionsLegalesPeriodiquesDe from "@/components/legal/periodiques/MentionsLegalesPeriodiquesDe.vue";

import { mapGetters } from "vuex";

/**
 * Affiche les mentions légales de Periodiques dans la bonne langue.
 */
export default {
  name: "MentionsLegalesPeriodiques",
  components: {
    MentionsLegalesPeriodiquesFr,
    MentionsLegalesPeriodiquesDe,
  },
  computed: {
    ...mapGetters(["country"]),
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/_legal.scss";
</style>

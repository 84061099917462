<template>
  <div
    id="mentions_legales_periodiques_de"
    class="text-regular"
  >
    <div class="bloc">
      <!-- TODO: Non disponible pour NBP pour l'instant -->
      {{ $t("legal.non-disponibles-sur cet-environnement") }}
    </div>
  </div>
</template>

<script>
/**
 * Affiche la page des CGU de Périodiques en allemand.
 */
export default {
  name: "MentionsLegalesPeriodiquesDe",
};
</script>

<style lang="scss"></style>
